import { defineMessages } from 'react-intl-next';

export default defineMessages({
	tryChat: {
		id: 'rovo-onboarding-try-chat',
		defaultMessage: 'Chat now',
		description: 'Action button for rovo onboarding spotlight',
	},
	dismissOnboarding: {
		id: 'rovo-onboarding-dismiss',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button for rovo onboarding spotlight',
	},
	onboardingHeading: {
		id: 'rovo-onboarding-heading',
		defaultMessage: 'Chat with AI',
		description: 'Onboarding step heading',
	},
	onboardingDescription: {
		id: 'rovo-onboarding-description',
		defaultMessage:
			'With Rovo Chat, use AI to explore company knowledge, create new things, and collaborate on your work.',
		description: 'Onboarding step description',
	},
});
