const MILLISECOND_IN_A_DAY = 86_400_000;

export function getTrialDaysLeft(timeLeft?: number | null) {
	if (timeLeft === null || timeLeft === undefined) {
		return undefined;
	}

	if (timeLeft <= 0) {
		return 0;
	}

	// Use Math.ceil to round up for fractional days
	return Math.ceil(timeLeft / MILLISECOND_IN_A_DAY);
}
