import React, { Fragment } from 'react';

import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import { Spotlight } from '@atlaskit/onboarding';
import { useAnalytics } from '@atlassian/conversation-assistant-instrumentation';

import {
	useConversationAssistantSpotlightActions,
	useConversationAssistantSpotlightState,
} from '../../controllers/spotlight';

import { SPOTLIGHT_ONBOARDING_TARGET } from './constants';
import messages from './messages';

export const ConversationAssistantOnboardingSpotlight = ({
	openRovoChat,
	openAgentSelectorMenu,
	onDismiss,
}: {
	openRovoChat?: (event: React.MouseEvent<HTMLElement>, analyticsEvent: any) => void;
	openAgentSelectorMenu?: () => void;
	onDismiss: () => void;
}) => {
	di(useConversationAssistantSpotlightState);

	const { formatMessage } = useIntl();
	const { end } = useConversationAssistantSpotlightActions();
	const { activeSpotlight } = useConversationAssistantSpotlightState();
	const { sendAnalyticsEvent } = useAnalytics();

	const renderActiveSpotlight = () => {
		const spotlights = [
			<Spotlight
				actions={[
					{
						onClick: (event, analyticsEvent) => {
							if (openRovoChat) {
								openRovoChat(event, analyticsEvent);
							}
							end();
							onDismiss();
							sendAnalyticsEvent({
								eventType: 'ui',
								// this is more of 'chatButtonClicked'
								action: 'dismissed',
								actionSubject: 'rovoChatButtonSpotlight',
							});
						},
						text: formatMessage(messages.tryChat),
					},
					{
						onClick: () => {
							end();
							onDismiss();
							sendAnalyticsEvent({
								eventType: 'ui',
								action: 'dismissButtonClicked',
								actionSubject: 'rovoChatButtonSpotlight',
							});
						},
						text: formatMessage(messages.dismissOnboarding),
						appearance: 'subtle',
					},
				]}
				heading={formatMessage(messages.onboardingHeading)}
				target={SPOTLIGHT_ONBOARDING_TARGET}
				key={SPOTLIGHT_ONBOARDING_TARGET}
				targetRadius={3}
			>
				{formatMessage(messages.onboardingDescription)}
			</Spotlight>,
		];

		if (activeSpotlight === null) {
			return null;
		}

		return spotlights[activeSpotlight];
	};

	return <Fragment>{renderActiveSpotlight()}</Fragment>;
};
