import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

import parameters, {
	type JiraApplicationKey,
	type JiraProjectType,
} from './__generated__/dialogFreeToStandardEditionAwarenessQuery.graphql';

export type EntryPointParams = {
	cloudId: string;
	hamsProductKey: string;
	projectType: JiraProjectType;
	jiraApplicationKey: JiraApplicationKey;
	chargeElement: string;
	isJira: boolean;
	isConfluence: boolean;
};

export const entryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "@atlaskit-internal_edition-awareness-free-to-standard-jira-products-dialog" */ './dialog'
			),
		{
			moduleId: 'edition-awareness-free-to-standard-jira-products-dialog',
		},
	),
	getPreloadProps: ({
		cloudId,
		hamsProductKey,
		projectType,
		jiraApplicationKey,
		chargeElement,
		isJira,
		isConfluence,
	}: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			tenantContexts: {
				parameters,
				variables: {
					cloudId,
					hamsProductKey,
					chargeElement,
					jiraApplicationKey,
					projectType,
					isJira,
					isConfluence,
				},
			},
		},
	}),
});
