/**
 * @generated SignedSource<<701b20045d1eee06ebb73d0d44f6290c>>
 * @relayHash 403b38c14dd5f740b9f46edfd78fe696
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d7d6b39759469500ddceb56494e8bf1cd8199c52538be6b0dac50f1efab923a4

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraApplicationKey = "JIRA_CORE" | "JIRA_PRODUCT_DISCOVERY" | "JIRA_SERVICE_DESK" | "JIRA_SOFTWARE" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type dialogStandardTrialLimitBreakersQuery$variables = {
  chargeElement: string;
  cloudId: string;
  hamsProductKey: string;
  jiraApplicationKey: JiraApplicationKey;
  projectType: JiraProjectType;
  shouldIncludeIfProductIsConfluence: boolean;
  shouldIncludeIfProductIsJiraSoftware: boolean;
};
export type dialogStandardTrialLimitBreakersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"limitBreakersModal_AtlassianEditionAwareness">;
};
export type dialogStandardTrialLimitBreakersQuery = {
  response: dialogStandardTrialLimitBreakersQuery$data;
  variables: dialogStandardTrialLimitBreakersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chargeElement"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hamsProductKey"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jiraApplicationKey"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectType"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldIncludeIfProductIsConfluence"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldIncludeIfProductIsJiraSoftware"
},
v7 = {
  "kind": "Variable",
  "name": "chargeElement",
  "variableName": "chargeElement"
},
v8 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v9 = {
  "kind": "Variable",
  "name": "hamsProductKey",
  "variableName": "hamsProductKey"
},
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v11 = [
  (v10/*: any*/),
  {
    "kind": "ScalarField",
    "name": "experienceUrl"
  }
],
v12 = {
  "kind": "ScalarField",
  "name": "id"
},
v13 = [
  (v12/*: any*/)
],
v14 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v15 = [
  {
    "kind": "Variable",
    "name": "applicationKey",
    "variableName": "jiraApplicationKey"
  },
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "dialogStandardTrialLimitBreakersQuery",
    "selections": [
      {
        "args": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "Variable",
            "name": "jiraApplicationKey",
            "variableName": "jiraApplicationKey"
          },
          {
            "kind": "Variable",
            "name": "projectType",
            "variableName": "projectType"
          },
          {
            "kind": "Variable",
            "name": "shouldIncludeIfProductIsConfluence",
            "variableName": "shouldIncludeIfProductIsConfluence"
          },
          {
            "kind": "Variable",
            "name": "shouldIncludeIfProductIsJiraSoftware",
            "variableName": "shouldIncludeIfProductIsJiraSoftware"
          }
        ],
        "kind": "FragmentSpread",
        "name": "limitBreakersModal_AtlassianEditionAwareness"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "dialogStandardTrialLimitBreakersQuery",
    "selections": [
      {
        "args": [
          {
            "items": [
              {
                "kind": "Variable",
                "name": "cloudIds.0",
                "variableName": "cloudId"
              }
            ],
            "kind": "ListValue",
            "name": "cloudIds"
          }
        ],
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": [
              (v9/*: any*/)
            ],
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "inTrialOrPreDunning": true
                    }
                  }
                ],
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "experienceCapabilities",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "configurePaymentV2",
                                    "plural": false,
                                    "selections": (v11/*: any*/)
                                  }
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v13/*: any*/),
                                "type": "CcpInvoiceGroup"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v13/*: any*/),
                        "type": "CcpSubscription"
                      },
                      {
                        "kind": "LinkedField",
                        "name": "trial",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "endTimestamp"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "experienceCapabilities",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "addPaymentMethodV2",
                            "plural": false,
                            "selections": (v11/*: any*/)
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v13/*: any*/),
                        "type": "CcpTransactionAccount"
                      }
                    ]
                  },
                  (v12/*: any*/),
                  {
                    "args": [
                      (v7/*: any*/)
                    ],
                    "kind": "ScalarField",
                    "name": "latestUsageForChargeElement"
                  }
                ],
                "storageKey": "entitlement(where:{\"inTrialOrPreDunning\":true})"
              }
            ]
          }
        ]
      },
      {
        "condition": "shouldIncludeIfProductIsConfluence",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v8/*: any*/)
            ],
            "concreteType": "ConfluenceStorage",
            "kind": "LinkedField",
            "name": "confluence_storage",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "bytesUsed"
              },
              {
                "kind": "ScalarField",
                "name": "isUnlimited"
              },
              {
                "kind": "ScalarField",
                "name": "bytesLimit"
              }
            ]
          },
          {
            "args": [
              (v8/*: any*/),
              {
                "kind": "Literal",
                "name": "cql",
                "value": "type=user"
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 200
              }
            ],
            "concreteType": "ConfluenceSearchConnection",
            "kind": "LinkedField",
            "name": "confluence_searchUser",
            "plural": false,
            "selections": [
              {
                "concreteType": "ConfluenceSearchResponse",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "ConfluencePerson",
                    "kind": "LinkedField",
                    "name": "confluencePerson",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "displayName"
                      },
                      {
                        "concreteType": "Icon",
                        "kind": "LinkedField",
                        "name": "profilePicture",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "path"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "shouldIncludeIfProductIsJiraSoftware",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "args": (v15/*: any*/),
                "kind": "ScalarField",
                "name": "attachmentStorageUsed"
              },
              {
                "args": (v15/*: any*/),
                "kind": "ScalarField",
                "name": "attachmentStorageAllowed"
              },
              {
                "args": (v15/*: any*/),
                "kind": "ScalarField",
                "name": "attachmentStorageIsUnlimited"
              },
              {
                "args": [
                  (v8/*: any*/),
                  {
                    "fields": [
                      {
                        "items": [
                          {
                            "kind": "Variable",
                            "name": "types.0",
                            "variableName": "projectType"
                          }
                        ],
                        "kind": "ListValue",
                        "name": "types"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "filter"
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  }
                ],
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "allJiraProjects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 20
                              }
                            ],
                            "concreteType": "JiraAssignableUsersConnection",
                            "kind": "LinkedField",
                            "name": "assignableUsers",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAssignableUsersEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v14/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "name"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "picture"
                                      },
                                      (v12/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "assignableUsers(first:20)"
                          },
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d7d6b39759469500ddceb56494e8bf1cd8199c52538be6b0dac50f1efab923a4",
    "metadata": {},
    "name": "dialogStandardTrialLimitBreakersQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "20d17aeb054ad27c6f63bc76677eb767";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
